import React from 'react';
import {Card, CardContent, CardHeader, Modal} from '@mui/material';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {Logout} from '@mui/icons-material';

const Home = (): React.JSX.Element => {
    const {t} = useTranslation(['home', 'common']);

    const {state} = useLocation();
    const [showLogout, setShowLogout] = React.useState(Boolean(state?.logout));

    const handleCloseLogoutModal = (): void => {
        setShowLogout(false);
        window.history.replaceState({}, '');
    };

    return (
        <>
            <Card>
                <CardHeader title={t('header')} />
                <HomeCardContent>
                    <p>
                        {t('blurb')}
                        <br />
                        {t('more_info')}
                    </p>
                    <LinkContainer>
                        <Link href="https://www.gls-mobilitaet.de/e-mobilitaet/giro-e/nutzersicht/" target="_blank">
                            <LinkImg src="/img/info-user.jpg" alt="" />
                            <span>{t('user_perspective')}</span>
                        </Link>
                        <Link href="https://www.gls-mobilitaet.de/e-mobilitaet/giro-e/betreibersicht/" target="_blank">
                            <LinkImg src="/img/info-operator.jpg" alt="" />
                            <span>{t('operator_perspective')}</span>
                        </Link>
                        <Link href="https://www.gls-mobilitaet.de/angebot" target="_blank">
                            <LinkImg src="/img/info-offerings.jpg" alt="" />
                            <span>{t('offerings')}</span>
                        </Link>
                        <Link href="https://www.gls-mobilitaet.de/neuigkeiten" target="_blank">
                            <LinkImg src="/img/news.jpg" alt="" />
                            <span>{t('news')}</span>
                        </Link>
                    </LinkContainer>
                </HomeCardContent>
            </Card>
            <CenterModal
                open={showLogout}
                onClose={handleCloseLogoutModal}
                slotProps={{backdrop: {'data-testid': 'logout-modal-backdrop'}} as any}
            >
                <Card>
                    <CenterCardContent>
                        <Logout />
                        <p>{t('common:login.logout_successful')}</p>
                    </CenterCardContent>
                </Card>
            </CenterModal>
        </>
    );
};

const HomeCardContent = styled(CardContent)`
    padding-top: 0;
`;

const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Link = styled.a`
    display: inline-flex;
    flex-direction: column;
    width: 25%;
    cursor: pointer;
    padding: 1rem;
    box-sizing: border-box;

    & span {
        text-align: center;
        color: ${props => props.theme.palette.text.primary as string};
    }

    &:hover img {
        border-color: #70d31e;
    }

    &:hover span {
        text-decoration: underline;
        color: #8daa0b;
    }
`;

const LinkImg = styled.img`
    border: 2px solid ${props => props.theme.palette.primary.main};
    border-radius: 50%;
    width: 100%;
    height: auto;
`;

const CenterModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
`;

const CenterCardContent = styled(CardContent)`
    display: flex;
    margin: 1rem;

    &:last-child {
        padding-bottom: 16px;
    }

    & > p {
        margin: 0 0 0 1rem;
    }
`;

export default Home;
